const firebaseConfig = {
  projectId: "seahorse-hzmsi",
  appId: "1:575337674491:web:d3fdea364708a4e5bee25e",
  databaseURL:
    "https://seahorse-hzmsi-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "seahorse-hzmsi.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyBN9abEbOkofg_4HkEcmxKL7EECpCgyO4M",
  authDomain: "seahorse-hzmsi.firebaseapp.com",
  messagingSenderId: "575337674491",
  measurementId: "G-GSGB27NDV4",
};

export default firebaseConfig;
